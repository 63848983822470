var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLBASEINFO" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.updateMode && _vm.deletable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBLREMOVE",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removeData },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.dataHistory,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-1" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBL0002885",
                              trueLabel: _vm.$label("LBLPERIOD"),
                              falseLabel: _vm.$label("LBL0002886"),
                              name: "rangeFlag",
                            },
                            on: { datachange: _vm.datachange },
                            model: {
                              value: _vm.dataHistory.rangeFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "rangeFlag", $$v)
                              },
                              expression: "dataHistory.rangeFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-datepicker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDate,
                                expression: "isDate",
                              },
                            ],
                            attrs: {
                              required: _vm.isDate,
                              editable: _vm.editable,
                              label: "작업기간",
                              range: true,
                              type: "date",
                              name: "workPeriod",
                            },
                            model: {
                              value: _vm.workPeriod,
                              callback: function ($$v) {
                                _vm.workPeriod = $$v
                              },
                              expression: "workPeriod",
                            },
                          }),
                          _c("c-datepicker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isDate,
                                expression: "!isDate",
                              },
                            ],
                            attrs: {
                              required: !_vm.isDate,
                              editable: _vm.editable,
                              label: "작업일자",
                              type: "date",
                              name: "startDt",
                            },
                            model: {
                              value: _vm.dataHistory.startDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "startDt", $$v)
                              },
                              expression: "dataHistory.startDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "PREVENTIVE_TYPE_CD",
                              required: true,
                              editable: _vm.editable && !_vm.updateMode,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "preventiveTypeCd",
                              label: "유지보수 구분",
                            },
                            model: {
                              value: _vm.dataHistory.preventiveTypeCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataHistory,
                                  "preventiveTypeCd",
                                  $$v
                                )
                              },
                              expression: "dataHistory.preventiveTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-air-preventive", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.popupParam.envAirMstPreventiveId,
                              type: "edit",
                              label: "방지시설",
                              name: "envAirMstPreventiveId",
                            },
                            model: {
                              value: _vm.dataHistory.envAirMstPreventiveId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataHistory,
                                  "envAirMstPreventiveId",
                                  $$v
                                )
                              },
                              expression: "dataHistory.envAirMstPreventiveId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "user",
                              label: "작업자",
                              name: "userId",
                            },
                            model: {
                              value: _vm.dataHistory.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "userId", $$v)
                              },
                              expression: "dataHistory.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.updateMode,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.dataHistory.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "plantCd", $$v)
                              },
                              expression: "dataHistory.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.dataHistory.preventiveTypeCd == "PTC0000002"
                              ? "col-8"
                              : "col-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              label: "문제점",
                              name: "problem",
                              rows: 2,
                            },
                            model: {
                              value: _vm.dataHistory.problem,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "problem", $$v)
                              },
                              expression: "dataHistory.problem",
                            },
                          }),
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              label: "작업내용",
                              name: "contents",
                              rows: 2,
                            },
                            model: {
                              value: _vm.dataHistory.contents,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "contents", $$v)
                              },
                              expression: "dataHistory.contents",
                            },
                          }),
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              label: "비고",
                              name: "remark",
                              rows: 2,
                            },
                            model: {
                              value: _vm.dataHistory.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataHistory, "remark", $$v)
                              },
                              expression: "dataHistory.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.dataHistory.preventiveTypeCd == "PTC0000002"
                        ? _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "c-table",
                                {
                                  ref: "grid",
                                  attrs: {
                                    title: "소모품",
                                    columns: _vm.grid.columns,
                                    data: _vm.dataHistory.partList,
                                    gridHeight: "290px",
                                    editable: _vm.editable,
                                    hideBottom: true,
                                    isExcelDown: false,
                                    filtering: false,
                                    isFullScreen: false,
                                    columnSetting: false,
                                    selection: "multiple",
                                    rowKey: "partCd",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "table-button" },
                                    [
                                      _c(
                                        "q-btn-group",
                                        { attrs: { outline: "" } },
                                        [
                                          _vm.editable
                                            ? _c("c-btn", {
                                                attrs: {
                                                  label: "LBLEXCEPT",
                                                  icon: "remove",
                                                },
                                                on: {
                                                  btnClicked: _vm.deletePart,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.editable
                                            ? _c("c-btn", {
                                                attrs: {
                                                  label: "LBLADD",
                                                  icon: "add",
                                                },
                                                on: { btnClicked: _vm.addPart },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachInfo,
                              editable: _vm.editable,
                              label: "유지보수 사진",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }