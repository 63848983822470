<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="updateMode && deletable" label="LBLREMOVE" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="dataHistory"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-1">
                <!-- 기간,일 -->
                <!-- true: 기간, false: 일 -->
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBL0002885"
                  :trueLabel="$label('LBLPERIOD')"
                  :falseLabel="$label('LBL0002886')"
                  name="rangeFlag"
                  v-model="dataHistory.rangeFlag"
                  @datachange="datachange"
                />
              </div>
              <div class="col-2">
                <c-datepicker
                  v-show="isDate"
                  :required="isDate"
                  :editable="editable"
                  label="작업기간"
                  :range="true"
                  type="date"
                  name="workPeriod"
                  v-model="workPeriod">
                </c-datepicker>
                <c-datepicker
                  v-show="!isDate"
                  :required="!isDate"
                  :editable="editable"
                  label="작업일자"
                  type="date"
                  name="startDt"
                  v-model="dataHistory.startDt">
                </c-datepicker>
              </div>
              <div class="col-2">
                <c-select
                  codeGroupCd="PREVENTIVE_TYPE_CD"
                  :required="true"
                  :editable="editable && !updateMode"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="preventiveTypeCd"
                  label="유지보수 구분"
                  v-model="dataHistory.preventiveTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-air-preventive
                  :required="true"
                  :editable="editable && !popupParam.envAirMstPreventiveId"
                  type="edit"
                  label="방지시설"
                  name="envAirMstPreventiveId"
                  v-model="dataHistory.envAirMstPreventiveId">
                </c-air-preventive>
              </div>
              <div class="col-2">
                <c-field 
                  :required="true"
                  :editable="editable"
                  type="user" 
                  label="작업자"
                  name="userId" 
                  v-model="dataHistory.userId" />
              </div>
              <div class="col-2">
                <c-plant :required="true" :editable="editable && !updateMode" type="edit" name="plantCd" v-model="dataHistory.plantCd" />
              </div>
              <div :class="dataHistory.preventiveTypeCd == 'PTC0000002' ? 'col-8' : 'col-12'">
                <c-textarea
                  :editable="editable"
                  label="문제점"
                  name="problem"
                  :rows="2"
                  v-model="dataHistory.problem">
                </c-textarea>
                <c-textarea
                  :editable="editable"
                  label="작업내용"
                  name="contents"
                  :rows="2"
                  v-model="dataHistory.contents">
                </c-textarea>
                <c-textarea
                  :editable="editable"
                  label="비고"
                  name="remark"
                  :rows="2"
                  v-model="dataHistory.remark">
                </c-textarea>
              </div>
              <div class="col-4" v-if="dataHistory.preventiveTypeCd == 'PTC0000002'">
                <c-table
                  ref="grid"
                  title="소모품"
                  :columns="grid.columns"
                  :data="dataHistory.partList"
                  gridHeight="290px"
                  :editable="editable"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="partCd"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <!--제외-->
                      <c-btn v-if="editable" label="LBLEXCEPT" icon="remove" @btnClicked="deletePart" />
                      <!--추가-->
                      <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addPart" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="유지보수 사진">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-part-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envAirDailyResultPreventiveHistoryId: '',
          envAirMstPreventiveId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'AIR_PREVENTIVE_PARTS',
        taskKey: '',
      },
      deletable: false,
      grid: {
        columns: [
          {
            name: 'partName',
            field: 'partName',
            label: '소모품',
            align: 'center',
            sortable: false,
          },
          {
            name: 'partCnt',
            field: 'partCnt',
            //비고
            label: '수량',
            align: 'right',
            style: 'width:130px',
            sortable: false,
            type: 'number'
          },
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      mappingType: 'POST',
      editable: true,
      isSave: false,
      updateMode: false,
      saveUrl: '',
      deleteUrl: '',
      detailUrl: '',
      dataHistory: {
        envAirDailyResultPreventiveHistoryId: '',  // 대기일지 방지시설 보수내역 일련번호
        envAirMstPreventiveId: '',  // 대기방지시설 일련번호
        preventiveTypeCd: null,  // 교체/보수 구분
        startDt: '',  // 보수시작일자
        endDt: '',  // 보수종료일자
        userId: '',  // 보수자
        remark: '',  // 비고
        problem: '',  // 문제점
        contents: '',  // 작업내용
        partDels: [], // 소모품 삭제목록
        partList: [], // 소모품 목록
        rangeFlag: 'N',
      },
      workPeriod: ['', ''],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    getGubun() {
      return this.dataHistory.preventiveTypeCd === 'PTC0000001' ? '보수' : (this.dataHistory.preventiveTypeCd === 'PTC0000002' ? '교체' : '보수/교체');
    },
    isDate() {
      return this.dataHistory.rangeFlag === 'Y'
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.daily.preventive.history.get.url;
      this.saveUrl = transactionConfig.env.air.daily.preventive.history.save.url;
      this.deleteUrl = transactionConfig.env.air.daily.preventive.history.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envAirDailyResultPreventiveHistoryId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirDailyResultPreventiveHistoryId: this.popupParam.envAirDailyResultPreventiveHistoryId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.dataHistory = _result.data;
          this.$emit('setRegInfo', _result.data);
          this.updateMode = true;
          this.workPeriod = [this.dataHistory.startDt, this.dataHistory.endDt];
          if (this.dataHistory.regUserId == this.$store.getters.user.userId) {
            this.deletable = true;
          } else {
            this.deletable = false;
          }
          this.$set(this.attachInfo, 'taskKey', this.popupParam.envAirDailyResultPreventiveHistoryId)
        },);
      } else {
        this.dataHistory.envAirMstPreventiveId = this.popupParam.envAirMstPreventiveId;
        this.dataHistory.userId = this.$store.getters.user.userId;
      }
    },
    saveInfo() {
      if (this.popupParam.envAirDailyResultPreventiveHistoryId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.dataHistory.regUserId = this.$store.getters.user.userId
              this.dataHistory.chgUserId = this.$store.getters.user.userId
              
              if (this.dataHistory.rangeFlag === 'Y') {
                if (this.workPeriod && this.workPeriod.length > 0) {
                  this.dataHistory.startDt = this.workPeriod[0];
                  this.dataHistory.endDt = this.workPeriod[1];
                }
              } else {
                this.dataHistory.startDt = this.dataHistory.startDt;
                this.dataHistory.endDt = this.dataHistory.startDt;
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      if(this.mappingType == 'POST') {
        this.popupParam.envAirDailyResultPreventiveHistoryId = result.data.envAirDailyResultPreventiveHistoryId;
        this.$set(this.attachInfo, 'taskKey', result.data.envAirDailyResultPreventiveHistoryId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    addPart() {
      this.popupOptions.title = '소모품 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'./airPartPopup.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePartPopup;
    },
    closePartPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.dataHistory.partList, { partCd: item.partCd }) === -1) {
            _data.push({
              envAirDailyResultPreventiveHistoryId: this.popupParam.envAirDailyResultPreventiveHistoryId,
              partCd: item.partCd,
              partName: item.partName,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.dataHistory.partList = this.$_.concat(_data, this.dataHistory.partList)
      }
    },
    deletePart() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000103', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.dataHistory.partDels) {
                this.dataHistory.partDels = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.dataHistory.partDels, { partCd: item.partCd }) === -1) {
                this.dataHistory.partDels.push(item)
              }
              this.dataHistory.partList = this.$_.reject(this.dataHistory.partList, item);
            });
            this.$refs['grid'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envAirDailyResultPreventiveHistoryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange() {
      this.dataHistory.startDt = '';
      this.dataHistory.endDt = '';
      if (this.educationInfo.educationRangeFlag === 'Y') {
        this.educationDate = '';
      } else {
        this.educationPeriod = ['', ''];
      }
    }
  }
};
</script>